<template>
  <div class="mod-config">
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>层级关系管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true" :model="dataForm">
<!--    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">-->
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="层级名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="selectByName()">查询</el-button>
        <el-button @click="getDataList()">重置</el-button>
<!--        <el-button v-if="isAuth('generator:level:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>-->
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button type="primary" @click="updateLevelFile()">导入</el-button>
<!--        <el-button v-if="isAuth('generator:level:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>-->
        <el-button  type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
        <el-button  type="danger" @click="clearEnable()" :disabled="enabledCount===0">清空启用</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      row-key="id"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
          prop="name"
          header-align="center"
          min-width="150"
          label="层级名称">
      </el-table-column>
      <el-table-column
          prop="level"
          header-align="center"
          align="center"
          label="层次位置">
      </el-table-column>
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        label="父层级名称">
      </el-table-column>
      <el-table-column
        prop="hasHbasetable"
        header-align="center"
        align="center"
        label="是否是叶子节点的直接父节点">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.hasHbasetable === 1" size="small">是</el-tag>
          <el-tag v-else-if="scope.row.hasHbasetable === 0" size="small" type="success">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="uname"
        header-align="center"
        align="center"
        label="创建者">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.uname === null" size="small">外部导入</el-tag>
          <div v-else>{{scope.row.uname}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="enabled"
          header-align="center"
          align="center"
          width="150"
          label="是否启用">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enabled === 1" size="small">已启用</el-tag>
          <el-tag v-else size="small" type="success">未启用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.createTable===0" type="text" size="small" @click="createTableHandle(scope.row.id,false,scope.row.hasHbasetable)">建表</el-button>
          <el-button v-if="scope.row.createTable===0" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button v-if="scope.row.createTable===0" type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>
          <el-button v-if="scope.row.createTable===1" type="text" size="small" @click="createTableHandle(scope.row.id,true,scope.row.hasHbasetable)">查看表格属性</el-button>
<!--          <el-button v-if="scope.row.createTable===1 & scope.row.enabled === 1" type="text" size="small" @click="toTable(scope.row.id)">跳转表格页面</el-button>-->
          <el-button v-if="scope.row.createTable===1 & scope.row.enabled === 1" type="text" size="small" @click="toTable(scope.row)">跳转表格页面</el-button>
          <el-button v-if="scope.row.createTable===1 & scope.row.enabled !== 1" type="text" size="small" @click="enableNode(scope.row.id)">启用</el-button>
<!--          <el-button v-if="scope.row.createTable===1 & scope.row.enabled !== 1" type="text" size="small" style="color: red" @click="deleteTable(scope.row.id)">删除表格</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      v-if="showpage"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="allFresh"></add-or-update>
    <create-table v-if="createTablevisible" ref="createTable" @refreshDataList="allFresh"></create-table>
    <level-enable v-if="enableVisible" ref="levelEnable" @refreshDataList="allFresh"></level-enable>
    <upload-file v-if="uploadFielVisible" ref="uploadFile" @refreshDataList="allFresh"></upload-file>
    <main-sidebar ref="mainSidebar"></main-sidebar>
  </div>
</template>

<script>
  import AddOrUpdate from './level-add-or-update'
  import CreateTable from './level-create-table'
  import {treeDataTranslate} from "@/utils";
  import mainSidebar from '../../main-sidebar'
  import LevelEnable from "./level-enable";
  import UploadFile from "./upload-file";
  export default {
    name: "levelManage",
    data () {
      return {
        dataForm: {
          key: ''
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        showpage: false,
        createTablevisible: false,
        enableVisible: false,
        uploadFielVisible: false,
        enabledCount: 0,
      }
    },
    components: {
      UploadFile,
      LevelEnable,
      AddOrUpdate,
      CreateTable,
      mainSidebar
    },
    created () {
      this.prepareData()
      this.getDataList()
    },
    methods: {
      //准备一些前期数据，用于控制操作按钮
      prepareData() {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/level/getEnabledCount'),
          method: 'get',
        }).then(({data}) => {
          if(data && data.code ===0)
          {
            this.enabledCount = data.enabledCount
          }
          this.dataListLoading = false
        })
      },

      allFresh(){
        this.prepareData()
        this.getDataList()
        this.reflashMenu()
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/level/listParent'),
          method: 'get',
        }).then(({data}) => {
          if(data && data.code ===0)
          {
            this.dataList = treeDataTranslate(data.levelList, 'id')
            this.dataListLoading = false
          }
          else{
            this.dataList =[]
          }
          this.showpage=false
          this.dataListLoading = false

        })
      },
      //通过名称进行搜索
      selectByName(){
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl('/level/selectByName'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'name': this.dataForm.key
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.levelList
            this.totalPage = data.count
            this.showpage=true;
          } else {
            this.dataList = []
            this.totalPage = 0
            this.showpage=true;
          }
          this.dataListLoading = false
        })
      },
      //通过层级进入某一个表格
      toTable(row){
        window.sessionStorage.setItem("enterState",0)
        window.sessionStorage.setItem("tableId",row.id)
        this.$router.push({
          name: 'universal-table',
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/level/delete'),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      //创建表格
      createTableHandle(id,show,hashbasetable){
        this.createTablevisible = true
        this.$nextTick(() => {
          this.$refs.createTable.init(id,show,hashbasetable)
        })
      },
      //启用层级以及其父层级
      enableNode(id){
        this.$http({
          url: this.$http.adornUrl('/level/enablebility?id='+id),
          method: 'get',
        }).then(({data})=>{
          if(data.enable==='0'|| data.enable===0){
            this.$alert("该层级的父层级中存在已启用层级，因此不能启用",'启用失败')
          }
          else {
            this.enableVisible=true
            this.$nextTick(() => {
              this.$refs.levelEnable.init(id)
            })
          }
        })
      },
      //上传并且处理xml文件
      updateLevelFile(){
        this.uploadFielVisible=true
        this.$nextTick(()=>{
          this.$refs.uploadFile.init()
        })
      },
      //删除表格并且清空该层级的表格属性
      deleteTable(id){

      },
      //解除表格的启用状态
      clearEnable(){
        this.$confirm(`这将导致系统启用的层级不再可用，是否还要继续进行？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/level/clearEnabled'),
            method: 'get',
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.prepareData()
                  this.getDataList()
                  this.reflashMenu()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      //在启用状态修改后，需要重新进行菜单栏数据的加载
      reflashMenu(){
        this.$http({
          url: this.$http.adornUrl('/sys/menu/nav'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
            sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
          } else {
            sessionStorage.setItem('menuList', '[]')
            sessionStorage.setItem('permissions', '[]')
          }
          // this.$refs.mainSidebar.routeHandle(this.$route);
        })
      },

    }
  }
</script>
